import { GrammarBox } from "./style";
import { copyToClipboard } from "../../util/react";
import { emphasizeWithStrong } from "../../util/grm";
import { FlashcardDto } from "../../types";
import { useState } from "react";
import { cutDescriptions } from "../../util/flashcards";

export const GrammarSide = ({ flashcard }: { flashcard: FlashcardDto }) => {
  let [title1, title2] = flashcard.engText.split("(");
  title2 = (title2 || "").replace(")", "");

  const [scrollable, setScrollable] = useState(false);
  const toggleScrollable = () => setScrollable(!scrollable);

  const descriptions = cutDescriptions(flashcard);

  const extraDescriptions = descriptions
    .slice(1)
    .map((d) => `<div style="color: blue">${d}</div>`)
    .join("");

  return (
    <GrammarBox
      scrollable={scrollable}
      onClick={() => {
        copyToClipboard(flashcard.engText);
      }}
    >
      <b>{title1}</b>
      <b className="title2">{title2}</b>
      <div
        dangerouslySetInnerHTML={{
          __html: emphasizeWithStrong(descriptions[0]) + extraDescriptions,
        }}
        onClick={toggleScrollable}
      />
    </GrammarBox>
  );
};
