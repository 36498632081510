import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { askWordFinder } from "../../util/api";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  form {
    display: flex;
  }

  input {
    padding: 10px;
    font-size: 1.2em;
    flex: 1;
  }
`;

const SuggestionText = styled.div`
  white-space: pre-wrap;
  font-weight: bold;
`;

interface WordFormData {
  input: string;
}

interface WordFinderProps {
  onClose?: () => void;
  autoFocus?: boolean;
}

export const WordFinder: FC<WordFinderProps> = ({ onClose, autoFocus }) => {
  const { register, handleSubmit, reset } = useForm<WordFormData>();
  const [input, setInput] = useState<string>("");
  const [suggestion, setSuggestion] = useState<string>("");

  // useQuery to fetch the word suggestion when input changes
  const { error, isFetching } = useQuery(["wordFinder", input], () => {
    if (input) {
      return askWordFinder(input).then(({ response, input: prevInput }) => {
        setSuggestion(response);
        if (input === prevInput) {
          setInput("");
        }
      });
    }
  });

  const onSubmit = ({ input }: WordFormData) => {
    setInput(input); // Update input state
    reset(); // Reset the form
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("input", { required: true })}
          autoFocus={autoFocus}
          placeholder="Enter a word or phrase"
          onKeyDown={(e) => {
            // close on escape
            if (e.key === "Escape") {
              onClose?.();
              setInput("");
            }
          }}
          autoComplete={"off"}
          onFocus={(e) => {
            (window as any).insightOpen = true;
          }}
          onBlur={(e) => {
            (window as any).insightOpen = false;
          }}
        />
      </form>
      {/* @ts-ignore */}
      <div>
        {isFetching && <p style={{ color: "blue" }}>Loading...</p>}
        {suggestion && (
          <SuggestionText
            dangerouslySetInnerHTML={{ __html: parseSuggestion(suggestion) }}
          />
        )}
        {error && (
          <p style={{ color: "red" }}>
            There was an error fetching the word suggestion.
          </p>
        )}
      </div>
    </Wrapper>
  );
};

function parseSuggestion(suggestion: string) {
  // replace ; with newline
  let val = suggestion.replace(/;/g, "\n");

  // add style for text in parentheses
  val = val.replace(
    /\(([^)]+)\)/g,
    "<span style='color: gray; font-weight: lighter'>($1)</span>"
  );

  return val;
}
