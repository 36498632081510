import { FC, useEffect, useState } from "react";
import { translate } from "../../util/translate";

interface TranslationProps {
  text: string;
  target: "en" | "pl";
}

export const Translation: FC<TranslationProps> = ({ text, target }) => {
  const [translatedText, setTranslatedText] = useState<string>(text);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTranslation = async () => {
      try {
        setLoading(true);
        const result = await translate(text, target, false);
        setTranslatedText(result);
      } catch (error) {
        console.error("Error translating text:", error);
        setTranslatedText(text); // Fallback to the original text in case of an error
      } finally {
        setLoading(false);
      }
    };

    fetchTranslation();
  }, [text, target]);

  if (loading) return null;

  return <div style={{ color: "blue" }}>{translatedText}</div>;
};
