import { FlashcardList } from "../components/FlashcardList";
import { FC, useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
  filterFlashcards,
  sortForPolishing,
  useShuffle,
} from "../util/flashcards";
import { Controls } from "../components/Controls";
import { useFlashcardsTitle } from "../util/title";
import { Outlet, useMatch } from "react-router";
import { AppContext } from "../components/AppContext";
import { AlgorithmBox } from "../components/AlgorithmBox";
import { isMobileDevice } from "../util/source";
import { SearchTranslate } from "../components/SearchTranslate";
import { FAVOURITES_CONTEXT } from "../components/Controls/consts";
import { useIsWriterPage, useWriter } from "../util/writer";
import { SavedOffline } from "../components/SavedOffline";

const StyledMain = styled.main<{ writer: boolean }>`
  padding-bottom: 40px;
  ${({ writer }) =>
    writer &&
    css`
      background: #4e4ee9;
    `}
`;

const NoIncompleteText = styled.p`
  text-align: center;
  padding: 60px 20px;
  font-size: 1.8em;
  line-height: 1.5;
`;

export const MainPage: FC = () => {
  const {
    flashcards,
    allContexts,
    algorithm,
    ringAlgorithm,
    collections,
    currentCollection,
    controlsState,
  } = useContext(AppContext);
  const isEditPage = Boolean(useMatch("/edit/:id"));
  const isAudio = Boolean(useMatch("/audio"));
  const isWriter = useIsWriterPage();

  const {
    startSide,
    contexts,
    completion,
    search,
    searchEverywhere,
    ringFavourite,
    reverse,
  } = controlsState.state;

  const currentCollectionItems =
    collections.find((col) => col.name === currentCollection)?.items || [];

  const useRingAlgorithm =
    ringFavourite && contexts.includes(FAVOURITES_CONTEXT);

  const filterFactors = [
    completion,
    searchEverywhere,
    ringFavourite,
    contexts,
    search,
    reverse,
    // algorithm,
  ] as const;

  let shownFlashcards = filterFlashcards(
    flashcards,
    ...filterFactors,
    currentCollectionItems
  );

  const usedAlgorithm = useRingAlgorithm ? ringAlgorithm : algorithm;

  const { isShuffled, shuffle, resetShuffle, isMarked, markedNumber } =
    useShuffle(shownFlashcards, usedAlgorithm, reverse);

  if (completion === "incomplete") {
    sortForPolishing(shownFlashcards, isMobileDevice());
  }

  shownFlashcards = useWriter(!search && isWriter, shownFlashcards);

  const shownCount = shownFlashcards?.length || 0;

  const noIncomplete = completion === "incomplete" && !shownCount;

  useFlashcardsTitle(shownCount);

  useEffect(() => {
    if (completion === "challenge" && isShuffled) {
      shuffle();
    }
  }, [...filterFactors, usedAlgorithm]);

  const [shownIndex, setShownIndex] = useState<number>(0);

  return (
    <StyledMain writer={isWriter}>
      {noIncomplete ? (
        <NoIncompleteText>
          Nieźle, nie ma nic do uzupełnienia! 🎉
        </NoIncompleteText>
      ) : (
        <FlashcardList
          flashcards={shownFlashcards}
          startSide={startSide}
          onProgress={setShownIndex}
          isMarked={isMarked}
          children={(chld) => (
            <>
              {completion === "incomplete" && <SavedOffline />}
              {isShuffled && !search && (
                <AlgorithmBox ring={useRingAlgorithm} />
              )}
              {chld}
              <SearchTranslate search={search} translationType="pol-to-eng" />
              {/*<SearchTranslate search={search} translationType="eng-to-pol" />*/}
            </>
          )}
        />
      )}
      {!isEditPage && (
        <Controls
          controlsState={controlsState}
          shuffle={shuffle}
          resetShuffle={resetShuffle}
          isShuffled={isShuffled}
          allContexts={allContexts}
          shownCount={shownCount}
          shownIndex={shownIndex}
          flashcards={shownFlashcards}
          autoPlay={isAudio}
          markedNumber={markedNumber}
        />
      )}
      <Outlet />
    </StyledMain>
  );
};
