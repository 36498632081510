import { usePersistedState } from "../../util/react";
import { Completion, Side } from "../../types";
import { useState } from "react";

export const useControlsState = () => {
  const startSideState = usePersistedState<Side>("side", "eng");
  const searchEverywhereState = usePersistedState("search-everywhere", false);
  const ringFavouriteState = usePersistedState("ring-favourite", true);
  const asyncLoaderState = usePersistedState("async-loader", false);
  const speakThresholdState = usePersistedState("speak-threshold", false);
  const voiceControlState = usePersistedState("voice-control", false);
  const writingAssistantState = usePersistedState("writing-assistant", false);
  const completionState = usePersistedState<Completion>(
    "completion",
    "challenge"
  );
  const contextsState = usePersistedState<string[]>("contexts", []);
  const searchState = useState<string>("");
  const autoDictState = usePersistedState("auto-dict", false);
  const reverseState = usePersistedState("reverse", false);

  const state = {
    startSide: startSideState[0],
    searchEverywhere: searchEverywhereState[0],
    ringFavourite: ringFavouriteState[0],
    asyncLoader: asyncLoaderState[0],
    speakThreshold: speakThresholdState[0],
    voiceControl: voiceControlState[0],
    writingAssistant: writingAssistantState[0],
    completion: completionState[0],
    contexts: contextsState[0],
    search: searchState[0],
    autoDict: autoDictState[0],
    reverse: reverseState[0],
  };

  return {
    startSideState,
    searchEverywhereState,
    ringFavouriteState,
    asyncLoaderState,
    speakThresholdState,
    voiceControlState,
    writingAssistantState,
    autoDictState,
    completionState,
    contextsState,
    searchState,
    reverseState,
    state,
  };
};

export type ControlsState = ReturnType<typeof useControlsState>;

export const useExtraControls = () => {
  const [extraControlsVisible, setExtraControlsVisible] = useState(false);
  const closeExtraControls = () => setExtraControlsVisible(false);
  const showExtraControls = () => setExtraControlsVisible(true);

  return [extraControlsVisible, closeExtraControls, showExtraControls] as const;
};
