import { useWritingAssistantQuery } from "../../util/queries";
import styled from "styled-components";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import { Translation } from "./Translation";

const AssistantText = styled.p`
  white-space: pre-wrap;
  color: #2f2f2f;
  line-height: 1.5em;
  text-align: left;
`;

const TextText = styled(AssistantText)`
  opacity: 0.8;
`;

export const WritingAssistant = ({
  text = "",
  crucialPhrase,
}: {
  text?: string;
  crucialPhrase?: string;
}) => {
  const assistantActive =
    useContext(AppContext).controlsState.writingAssistantState[0];

  const [data] = useWritingAssistantQuery(
    assistantActive ? text : "",
    crucialPhrase
  );

  if (!data || !data.response) {
    return null;
  }

  return (
    <>
      <TextText>{data.text}</TextText>
      <AssistantText>💡 {data.response}</AssistantText>
      <Translation text={data.text} target={"pl"} />
    </>
  );
};
