import { NewButton, NewButtonWrapper } from "./style";
import {
  getTopicAbbrev,
  getTopicConfigPath,
  setWillAddQuestion,
  useTopicColors,
} from "../Quizes/utils/quiz";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router";
import { Modal } from "../Modal";
import styled from "styled-components";
import { ModalButton } from "../ModalComponents";
import { QuizQuestionTopic, quizTopics } from "../../types";
import { WordFinderButton } from "../WordFinder/WordFinderButton";

const InnModal = styled.div`
  padding: 70px;
  border-radius: 50%;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  button {
    width: 100px;
    height: 100px;
  }
`;

interface ButtonAddProps {
  autoPlay: boolean;
}

export const ButtonAdd: FC<ButtonAddProps> = ({ autoPlay }) => {
  const navigate = useNavigate();
  const getTopicColor = useTopicColors();

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  const getQuizAdd = (topic: QuizQuestionTopic) => (
    <ModalButton
      onClick={() => {
        setWillAddQuestion();
        navigate(getTopicConfigPath(topic));
      }}
      magicColor={getTopicColor(topic)}
    >
      <p>🎲</p>
      <p>{getTopicAbbrev(topic)}</p>
    </ModalButton>
  );

  return (
    <>
      <NewButtonWrapper>
        {/*<NewButtonUp*/}
        {/*  onClick={() => navigate("/add-voice")}*/}
        {/*  onRightClick={() => {*/}
        {/*    setWillAddQuestion();*/}
        {/*    navigate(QUIZ_PATHS.GERUND_CONFIG);*/}
        {/*  }}*/}
        {/*  topStyle*/}
        {/*>*/}
        {/*  <span>🎤</span>*/}
        {/*</NewButtonUp>*/}
        <WordFinderButton />

        {/*<NewButtonUp*/}
        {/*  onClick={() => {*/}
        {/*    navigate("/audio");*/}
        {/*  }}*/}
        {/*  topStyle*/}
        {/*  isActive={autoPlay}*/}
        {/*>*/}
        {/*  <span>🔈</span>*/}
        {/*</NewButtonUp>*/}
        <NewButton
          onClick={() => navigate("/add")}
          topStyle
          onRightClick={() => {
            setShowModal(true);
          }}
        >
          🧷
        </NewButton>
        {showModal && (
          <Modal onClickOutside={closeModal} box={InnModal}>
            {quizTopics.map((topic) => getQuizAdd(topic))}
            <div />
          </Modal>
        )}
      </NewButtonWrapper>
    </>
  );
};
