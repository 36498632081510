import { FC, useEffect, useState } from "react";
import { Modal } from "../Modal";
import { WordFinder } from "./WordFinder";
import { StyledWordFinder } from "../Controls/style";

export const WordFinderButton: FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
  };

  // display modal on ctrl + k - react effect
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === "k") {
        setModalOpen((val) => !val);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <>
      <StyledWordFinder
        topStyle
        onClick={(e) => {
          e?.stopPropagation();
          openModal();
        }}
      >
        🛟
      </StyledWordFinder>

      {isModalOpen && (
        <Modal onClickOutside={closeModal} width={600}>
          <WordFinder onClose={closeModal} autoFocus />
        </Modal>
      )}
    </>
  );
};
